import React from 'react'
import { Link } from 'react-router-dom'

const ContactButton = () => {
  return (
    <div>
        <div className="contact-button">
        
        <div className="call-button">
      <Link className="call-button-icon"  target="_blank" to="tel:+918920770571">
              <i class="fa-solid fa-phone-volume"></i>
          </Link>
    </div>



    <div className="whatsapp-button">
      <Link className="whatsapp-button-icon" target="_blank" to="https://wa.me/+918920770571?text=Hello ! I'm Interested in Summer Camp. Details please !">
        <i class="fa-brands fa-whatsapp"></i>
      </Link>
    </div>


      </div>
    </div>
  )
}

export default ContactButton