import React, { useState } from "react";
import { Link } from "react-router-dom";

const IPFaqComponent = () => {
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  return (
    <div>
      <div className="ip-faq">
        <div className="ip-faq-heading">Frequently Asked Questions</div>
        <div className="ip-faq-content">
          <div className="ip-faq-content-col">
            <div className="ip-faq-content-col-ques">
              <span>
                1. What will be covered in the IELTS / PTE Online Coaching Program?
              </span>
              {show1 ? (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow1(false)}
                >
                  +
                </span>
              ) : (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow1(true)}
                >
                  -
                </span>
              )}
            </div>

            {show1 ? (
              <div className="ip-faq-content-col-ans">
                It will be a live 4-week batch, we cover all four modules of
                IELTS / PTE in daily 1-hour interactive live classes. From the basics
                to advanced strategies, we guide you through the correct
                approaches, tips, and techniques to excel in the IELTS / PTE exam.
                Additionally, there will be daily Live Doubt solving sessions in
                which you can connect to Mentor and ask your queries in real
                time.
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="ip-faq-content-col">
            <div className="ip-faq-content-col-ques">
              <span>2. What are the class timings?</span>
              {show2 ? (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow2(false)}
                >
                  +
                </span>
              ) : (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow2(true)}
                >
                  -
                </span>
              )}
            </div>

            {show2 ? (
              <div className="ip-faq-content-col-ans">
                We have multiple batches at various timings, you can choose a
                batch that fits your schedule.
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="ip-faq-content-col">
            <div className="ip-faq-content-col-ques">
              <span>
                3. Are the courses for IELTS / PTE Academic or General Training
                students?
              </span>
              {show3 ? (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow3(false)}
                >
                  +
                </span>
              ) : (
                <span
                  className="ip-faq-content-col-ques-btn"
                  onClick={(e) => setShow3(true)}
                >
                  -
                </span>
              )}
            </div>

            {show3 ? (
              <div className="ip-faq-content-col-ans">
                Our IELTS / PTE courses are suitable for both Academic test takers and
                General test takers.
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="ip-faq-talk">
          <div className="ip-faq-talk-col">
            <div className="ip-faq-talk-col-heading">Ready To Talk With "We Excel Learning Center" </div>
            <div className="ip-faq-talk-col-text">Connect With India’s Finest Counsellors And Biggest Study Abroad Community.</div>
          </div>
          <div className="ip-faq-talk-col">
            <div className="ip-faq-talk-col-call"><Link to="tel:+918920770571" target="_blank">Talk To A Counsellor</Link></div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default IPFaqComponent;
