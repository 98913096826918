import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // navigate("/summer-fiesta");
    navigate("/ielts-coaching");
  }, []);

  return <div></div>;
};

export default HomePage;
