import React, { useState } from "react";
import logo from "../media/images/we-excel-logo.png";
import BookingForm from "./BookingForm";

const Footer = () => {
  const [form, setForm] = useState(false);

  return (
    <div>
      {form ? <BookingForm setForm={setForm} /> : ""}
      <div className="footer">
        <div>
          <div className="footer-cols">
            <div className="footer-col-1">
              <div className="footer-col-1-logo">
                <img src={logo} alt="" />
              </div>

              <div className="footer-col-1-text">
                Transforming dreams into reality, "We Excel Learning Center"
                guarantees success with cutting-edge education, personalized
                mentorship, and unwavering dedication. <br /> Join us today!
              </div>
            </div>
            <div className="footer-col-2">
              <div className="footer-col-2-col">
                <div className="footer-col-2-col-heading">INFORMATION</div>

                <div className="footer-col-2-col-points">
                  <div>
                    <span>»</span> <a href="#home">Home</a>
                  </div>
                  <div>
                    <span>»</span> <a href="#about">About</a>
                  </div>
                  <div>
                    <span>»</span> <a href="#why">Why Us</a>
                  </div>
                  <div>
                    <span>»</span> <a href="#welcome">Welcome</a>
                  </div>
                  <div>
                    <span>»</span>{" "}
                    <div
                      onClick={(e) => {
                        setForm(true);
                      }}
                    >
                      Book Seat
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-col-2-col">
                <div className="footer-col-2-col-heading">CONTACT</div>
                <div className="footer-col-2-col-points">
                  <div>
                    <span>
                      <i class="fa-solid fa-location-arrow"></i>
                    </span>{" "}
                    <div>
                      210, 2nd Floor, Ocean Plaza,
                      <br />
                      Sector-18, Noida - 201301
                    </div>
                  </div>
                  <div>
                    <span>
                      <i class="fa-solid fa-mobile-screen-button"></i>
                    </span>{" "}
                    <div>+91 89207 70571</div>
                  </div>
                  <div>
                    <span>
                      <i class="fa-solid fa-envelope"></i>
                    </span>{" "}
                    <div>weexcellearningcenter@gmail.com</div>
                  </div>
                  <div>
                    <span>
                      <i class="fa-brands fa-whatsapp"></i>
                    </span>{" "}
                    <div>+91 89207 70571</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-cc">
          Copyright <div className="fa-regular fa-copyright"></div> 2024, We
          Excel Learning Center. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
