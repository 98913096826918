import React from "react";
import bell from "../../media/images/ilets-pte/bell.png";
import header from "../../media/images/ilets-pte/Group-50-1.png";
import correct from "../../media/images/ilets-pte/correct.png";
import gicon from "../../media/images/ilets-pte/google-icon.png";
import { Link } from "react-router-dom";

const IPHeaderComponent = () => {
  return (
    <div>
      <div className="ip-header-top">
        <img src={bell} alt="" />
        <div>
          <span>ATTENTION:</span> Your Search for the Most Reliable IELTS / PTE ONLINE COACHING CLASS ENDS HERE!!!
        </div>
      </div>

      <div className="ip-header-content">

        <div className="ip-header-content-col-1">
          <img src={header} alt="" />
        </div>
        
        <div className="ip-header-content-col-2">
          <div className="ip-header-content-col-2-heading">
            Wondering How to Score <span>8+ Bands In 4 Weeks?</span>
          </div>
          <div className="ip-header-content-col-2-points-col">

            <div className="ip-header-content-col-2-point">
              <div className="ip-header-content-col-2-point-text"><img src={correct} alt="" /><span>Best Strategies to Crack 8+ Bands</span></div>
            </div>
            

            <div className="ip-header-content-col-2-point">
              <div className="ip-header-content-col-2-point-text"><img src={correct} alt="" /><span>Batch Size of 5 students</span></div>
            </div>
            

            <div className="ip-header-content-col-2-point">
              <div className="ip-header-content-col-2-point-text"><img src={correct} alt="" /><span>Expert Evaluation & Feedback</span></div>
            </div>
            

            <div className="ip-header-content-col-2-point">
              <div className="ip-header-content-col-2-point-text"><img src={correct} alt="" /><span>Comprehensive Preparation Guide</span></div>
            </div>
            
          </div>

          <div className="ip-header-content-col-2-rating">
            <img src={gicon} alt="" />
            <span>4.9</span>
            <div>
            <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
            </div>
          </div>

          <div className="ip-header-content-col-2-button">
            {/* <Link target="_blank" to="tel:+918920770571">Call Now</Link> */}
            <Link target="_blank" tittle="call Counsellor" to="tel:+91-9953445973">Call Now</Link>
          </div>


          
        </div>
        
      </div>
    </div>
  );
};

export default IPHeaderComponent;
