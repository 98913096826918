import React from "react";
import { Link } from "react-router-dom";
import logo from "../../media/images/ilets-pte/we-excel-logo.png"

const IPFooterComponent = () => {
  return (
    <div>
      <div className="ip-footer">
        <div className="ip-footer-content">
          <div className="ip-footer-content-col">
            <div className="ip-footer-content-col-logo"><img src={logo} alt="logo" /></div>
            <div className="ip-footer-content-col-link"><Link to="tel:+918920770571" target="_blank">Talk To A Counsellor</Link> </div>
          </div>
          <div className="ip-footer-content-col-2">

            <div className="ip-footer-content-col-contact">
              <div className="ip-footer-content-col-contact-icon"><i class="fa-solid fa-location-dot"></i></div>
              <div className="ip-footer-content-col-contact-text">
                <div className="ip-footer-content-col-contact-text-heading">Noida - India</div>
                <div className="ip-footer-content-col-contact-text-txt">210, 2nd Floor, Ocean Plaza,<br />Sector-18, Noida - 201301</div>
              </div>
            </div>
            

            <div className="ip-footer-content-col-contact">
              <div className="ip-footer-content-col-contact-icon"><i class="fa-solid fa-phone"></i></div>
              <div className="ip-footer-content-col-contact-text">
                <div className="ip-footer-content-col-contact-text-heading">Call Now</div>
                <div className="ip-footer-content-col-contact-text-txt">+91 8920770571</div>
                <div className="ip-footer-content-col-contact-text-txt">+91 9953445973</div>
              </div>
            </div>

          </div>
          <div className="ip-footer-content-col">
          <div className="ip-footer-content-col-follow">
          <div className="ip-footer-content-col-follow-heading">Follow Us</div>
          <div className="ip-footer-content-col-follow-icons">
            {/* <Link target="_blank" to=""><i class="fa-brands fa-facebook-f"></i></Link> */}
            {/* <Link target="_blank" to="https://www.instagram.com/boloenglishbolo?igsh=MXhxNzc1aG1tMzY1aw=="><i class="fa-brands fa-instagram"></i></Link> */}
            <Link target="_blank" to="https://www.instagram.com/advanceincareerwith_deeksha/"><i class="fa-brands fa-instagram"></i></Link>
            <Link target="_blank" to="https://www.linkedin.com/in/ieltswithdeeksha/"><i class="fa-brands fa-linkedin-in"></i></Link>
          </div>

          </div>

          </div>
        </div>


        <div className="ip-footer-cc">
        <div className="ip-footer-cc-text-1">“We Excel Learning Center” Copyright <i class="fa-regular fa-copyright"></i> 2024. All right reserved</div>
        <div className="ip-footer-cc-text-2">Disclaimer: We use cookies to personalise your experience and to analyse our website traffic. By continuing to use our website, you consent to our cookies.</div>

        </div>
        
        
      </div>
    </div>
  );
};

export default IPFooterComponent;
