import React, { useState } from "react";
// import logo from "../media/images/we-logo.jpeg";
import logo1 from "../media/images/we-excel-logo.png";
// import tree from "../media/images/tree.png";
import header from "../media/images/header-1.png";
import { TypeAnimation } from "react-type-animation";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import BookingForm from "./BookingForm";

const Header = () => {

  const [form, setForm] = useState(false)
  
  return (
        <div>
{
  form?
  <BookingForm setForm={setForm}/>
  :""
}
          
    <div id="home" className="header">
        <div>
        <div className="header-top">
          <span>
          {/* Hurry Up ! Only Limited Seats Available */}
          Hurry Up ! 10 seats available only. Avail 30% discount
          </span>
        </div>

          <div>
        <div className="header-nav">
            <div className="header-nav-logo">
              <img src={logo1} alt="" />
            </div>
            <Link to="tel:+918920770571" target="_blank" className="header-nav-button">Call Now</Link>
          </div>
        </div>


        <div className="header-content">
        <div className="header-content-col-1">
          <img src={header} alt="" />
          </div>
        <div className="header-content-col-2">
        <div className="header-content-col-2-heading-1">
        "Exciting Camp Awaits <br />Join us Now"
        </div>
        <div className="header-content-col-2-heading-2">
        <TypeAnimation
                    sequence={[
                      "Rock Painting",
                      3500,
                      "Art & Craft",
                      3500,
                      "Puppet Show",
                      3500,
                      "Yoga",
                      3500,
                      "Coding",
                      3500,
                      "Current Affairs",
                      3500,
                      "Science Experiments",
                      3500,
                      "French Classes",
                      3500,
                      "Non-Fire Cooking",
                      3500,
                      "Chess",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
        </div>
        <div className="header-content-col-2-button"><span onClick={e=>{setForm(true)}}>Book Seat Now</span></div>

        </div>
          
        </div>
        
      </div>
    </div>

    <div id="about" className="header-numbers">
    <div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={14} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">
            Years experience
          </div>
        </div>



        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10} duration="3" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Activities</div>
        </div>
        
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={1000} duration="3"  enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Happy Students</div>
        </div>
      </div>
    </div>
    
    
    </div>
  );
};

export default Header;
