import React from 'react'
import IPHeaderComponent from '../components/ILETS-PTE/IPHeaderComponent'
import IPFormComponent from '../components/ILETS-PTE/IPFormComponent'
import IPCuriousComponent from '../components/ILETS-PTE/IPCuriousComponent'
import IPBenefits from '../components/ILETS-PTE/IPBenefits'
import IPPerfectChoiceComponent from '../components/ILETS-PTE/IPPerfectChoiceComponent'
import IPTrainerComponent from '../components/ILETS-PTE/IPTrainerComponent'
import IPFooterComponent from '../components/ILETS-PTE/IPFooterComponent'
import IPFaqComponent from '../components/ILETS-PTE/IPFaqComponent'
import IPContactFixButton from '../components/ILETS-PTE/IPContactFixButton'

const IletsPtePage = () => {
  return (
    <div>
        <IPHeaderComponent/>
        <IPFormComponent/>
        <IPCuriousComponent/>
        <IPBenefits/>
        <IPPerfectChoiceComponent/>
        <IPTrainerComponent/>
        <IPFaqComponent/>
        <IPFooterComponent/>

        <IPContactFixButton/>
        
    </div>
  )
}

export default IletsPtePage