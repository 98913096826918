import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../LoadingComponent";

const IPFormComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    number: "",
    coaching_type: "",
    currently_doing: "",
    exam_duration: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch(
        "https://api.weexcellearningcenter.in/ielts-pte-booking",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("booked successful");
        navigate("/booking-confirm");
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  return (
    <div className="ip-form">
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      <div>
        <div>
          <div className="ip-form-heading">
            Join <span>IELTS / PTE</span> training
          </div>
          <div className="ip-form-form">
            <form onSubmit={handleSubmit}>
              <input onChange={handleChange} required type="text" name="name" placeholder="Name" />
              <input onChange={handleChange} required type="email" name="email" placeholder="Email" />

              <div className="ip-form-form-input">
                <input onChange={handleChange} required type="number" name="number" placeholder="Enter Phone No." />
              </div>

              <div className="ip-form-form-input-heading">
                What's your IELTS / PTE Type?
              </div>

              <div className="ip-form-form-input-option">
                <span>
                  <input onChange={handleChange} value="General" required type="radio" name="coaching_type" />
                  <label>General</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Academic" required type="radio" name="coaching_type" />
                  <label>Academic</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Not Sure" required type="radio" name="coaching_type" />
                  <label>Not Sure</label>
                </span>
              </div>

              <div className="ip-form-form-input-heading">
                What are you currently doing?
              </div>

              <div className="ip-form-form-input-option">
                <span>
                  <input onChange={handleChange} value="Student" required type="radio" name="currently_doing" />
                  <label>Student</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Working" required type="radio" name="currently_doing" />
                  <label>Working</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Recently Graduated" required type="radio" name="currently_doing" />
                  <label>Recently Graduated</label>
                </span>
              </div>

              <div className="ip-form-form-input-heading">
                When are you taking IELTS / PTE Exam?
              </div>

              <div className="ip-form-form-input-option">
                <span>
                  <input onChange={handleChange} value="Within 2 Months" required type="radio" name="exam_duration" />
                  <label>Within 2 Months</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Within 2-4 Months" required type="radio" name="exam_duration" />
                  <label>Within 2-4 Months</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Already Booked" required type="radio" name="exam_duration" />
                  <label>Already Booked</label>
                </span>

                <span>
                  <input onChange={handleChange} value="Not Sure" required type="radio" name="exam_duration" />
                  <label>Not Sure</label>
                </span>
              </div>

              <button>SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPFormComponent;
