import React from "react";
import img from "../../media/images/ilets-pte/Mask-group-23.webp"

const IPTrainerComponent = () => {
  return (
      <div className="ip-trainer">
          <div>
        <div className="ip-trainer-heading">
          Learn From The Best IELTS / PTE Trainer In India
        </div>
        <div className="ip-trainer-content">
          <div className="ip-trainer-content-img"><img src={img} alt="" /></div>
          <div className="ip-trainer-content-col">
            <div className="ip-trainer-content-col-heading">Deeksha Kapoor</div>
            <div className="ip-trainer-content-col-text">
              Deeksha Kapoor, a certified IELTS / PTE Trainer from British Council &
              PTE Pearson’s Institute with an experience of 14 years is all set
              to start her entrepreneurship at “We Excel Learning Center” to
              guide TEST TAKERS like YOU. Take a personalized demo today with
              our expert coach to ease your journey while preparing for lELTS.
              She is an experienced English Language Teacher with a demonstrated
              history of working in the writing and editing industry. Skilled in
              English, Proofreading, Editing, Curriculum Development, and
              Writing. Strong education professional with a Master in English
              (M.A.) and Advanced Diploma in French Language from Hans Raj
              College, Delhi University (2009). She has a proven track record of
              training students in IELTS / PTE, PTE, OET, CELPIP exams across national
              as well as international borders.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPTrainerComponent;
