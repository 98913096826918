import React from 'react'
import icon1 from "../../media/images/ilets-pte/Group-49.png"
import icon2 from "../../media/images/ilets-pte/Group-50.png"
import icon3 from "../../media/images/ilets-pte/Group-51.png"

const IPCuriousComponent = () => {
  return (
    <div>
        <div className="ip-curious">
        <div className="ip-curious-heading">Curious? What Will You Learn In This Powerful Demo Class?</div>
        <div className="ip-curious-content">

        <div className="ip-curious-content-col">
        <div className="ip-curious-content-col-img"><img src={icon1} alt="" /></div>
        <div className="ip-curious-content-col-content">
        <div className="ip-curious-content-col-content-heading">9 band Tips and Techniques for Listening and Reading Module</div>
        <div className="ip-curious-content-col-content-text">Learn how to complete your Reading Module in given Time Frame & What is correct approach to crack listening with 9 Band Score.</div>
            </div>    
        </div>

        <div className="ip-curious-content-col">
        <div className="ip-curious-content-col-img"><img src={icon2} alt="" /></div>
        <div className="ip-curious-content-col-content">
        <div className="ip-curious-content-col-content-heading">Proven 8+ Band strategy for Writing Module</div>
        <div className="ip-curious-content-col-content-text">Strategies to solve writing tasks & How to use Lexical resources properly to boost writing score easily.</div>
            </div>    
        </div>

        <div className="ip-curious-content-col">
        <div className="ip-curious-content-col-img"><img src={icon3} alt="" /></div>
        <div className="ip-curious-content-col-content">
        <div className="ip-curious-content-col-content-heading">Correct Blueprint to ace Speaking Exam</div>
        <div className="ip-curious-content-col-content-text">Strategy for Cue-Cards & Techniques for generating better content ideas and to boost confidence level in front of examiner</div>
            </div>    
        </div>

        </div>

        </div>
    </div>
  )
}

export default IPCuriousComponent