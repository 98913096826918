import React from 'react'

const IPBenefits = () => {
  return (
      <div className="ip-benefit">
            <div>
        <div className="ip-benefit-heading">Exclusive Benefits Of Joining This Community</div>
        <div className="ip-benefit-content">
            
        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">01</div>
        <div className="ip-benefit-content-col-heading">India’s Top IDP Certified Trainer</div>
        <div className="ip-benefit-content-col-text">Learn from India’s Best IELTS / PTE Trainer. Get an exclusive mentor to guide you throughout the IELTS / PTE online preparation journey.</div>

        </div>

        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">02</div>
        <div className="ip-benefit-content-col-heading">Interactive Classes</div>
        <div className="ip-benefit-content-col-text">Join lively online classes, engage in discussions, and practice real exam scenarios with us.</div>

        </div>

        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">03</div>
        <div className="ip-benefit-content-col-heading">Daily Doubt Solving Sessions</div>
        <div className="ip-benefit-content-col-text">Additional doubt resolving sessions on a daily basis.</div>

        </div>

        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">04</div>
        <div className="ip-benefit-content-col-heading">Practice Tests</div>
        <div className="ip-benefit-content-col-text">Get 50+ IELTS / PTE Mock Tests with Speaking and writing evaluations by experts.</div>

        </div>

        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">05</div>
        <div className="ip-benefit-content-col-heading">Convenient and Flexible Timings</div>
        <div className="ip-benefit-content-col-text">Select IELTS / PTE online classes that suit your schedule. Join weekday or weekend batches suitable to your timings.</div>

        </div>

        <div className="ip-benefit-content-col">
        <div className="ip-benefit-content-col-number">06</div>
        <div className="ip-benefit-content-col-heading">Personalised Guidance</div>
        <div className="ip-benefit-content-col-text">We ensure that everyone gets personal attention and guidance from the IELTS / PTE trainers.</div>

        </div>

        </div>

        </div>
    </div>
  )
}

export default IPBenefits