import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const IPContactFixButton = () => {
    const [show, setShow] = useState(false)
  return (
    <div>
        <div className="ip-fix-contact">
{ show?
        <div className="ip-fix-contact-content">
        <div className="ip-fix-contact-btn">
            <Link target='_blank' to="tel:+918920770571"><i class="fa-solid fa-phone"></i></Link>
        </div>
        <div className="ip-fix-contact-btn">
            <Link target='_blank' to="https://wa.me/+918920770571?text=Hello ! I'm Interested in ILETS / PTE Coaching. Details please !"><i class="fa-brands fa-whatsapp"></i></Link>
        </div>

        </div>
        :""}

        {
            show?
            <div onClick={e=>setShow(false)} className="ip-fix-contact-btn"><i class="fa-solid fa-xmark"></i></div>
:
            <div onClick={e=>setShow(true)} className="ip-fix-contact-btn"><i class="fa-solid fa-message"></i></div>
        }


            
        </div>
        
        
        
    
    </div>
  )
}

export default IPContactFixButton