import React, { useState } from "react";
import icon from "../media/images/correct.png"
import BookingForm from "./BookingForm";

const WelcomeComponent = () => {
  
  const [form, setForm] = useState(false)
  return (
    <div>
    {
      form?
      <BookingForm setForm={setForm}/>
      :""
    }
    <div id="welcome" className="welcome-comp">
      <div>
        <div className="welcome-comp-heading">
          Welcome to <span>We Excel Learning Center</span>
        </div>
        <div className="welcome-comp-content">
        <div className="welcome-comp-content-col-1">
            <img src="" alt="" />
        </div>
        <div className="welcome-comp-content-col-2">
        <div className="welcome-comp-content-col-2-heading-0">Namaste Parents 🙏,</div>
        <div className="welcome-comp-content-col-2-text">We are delighted to announce the commencement of our "Summer Camp" that is full of Fun, Adventure and Learning at Sector-18 Noida(Building- Ocean Plaza).</div>
        <div className="welcome-comp-content-col-2-heading-1">Camp Details</div>
        <div className="welcome-comp-content-col-2-points">
            <div><span><img src={icon} alt="" /></span> <span> <b>Age Group: </b> 5-11 Years</span></div>
            <div><span><img src={icon} alt="" /></span> <span> <b>Date: </b> 20th May'24 Onwards</span></div>
            <div><span><img src={icon} alt="" /></span> <span> <b>Timings: </b> 10:00 AM to 12:00 PM (5-8 years) & 11:00 AM to 1:00 PM(9-11 years)</span></div>
            <div><span><img src={icon} alt="" /></span> <span> <b>Pick & Drop facility </b></span></div>
        </div>
        <div className="welcome-comp-content-col-2-text">Secure your child's spot today and let the fun begin! </div>
        <div className="welcome-comp-content-col-2-heading-2">Warm regards,</div>
        <div className="welcome-comp-content-col-2-heading-2">Team We Excel Learning Center</div>
        
        <div className="welcome-comp-content-col-2-button"  onClick={e=>{setForm(true)}}>
Book Seat Now
        </div>

        </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default WelcomeComponent;
