import React from 'react'
import './App.css';
import './css/Header.css';
import './css/NumberIncrement.css';
import './css/Buttons.css';
import './css/Animation.css';
import './css/BookingForm.css';
import './css/WelcomeComponent.css';
import './css/TeacherComponent.css';
import './css/Footer.css';
import './css/WhyComponent.css';
import './css/ErrorPage.css';
import './css/Loading.css';
import './css/ConfirmBooking.css';
import './css/ILETS-PTE/IPHeaderComponents.css';
import './css/ILETS-PTE/IPFormComponent.css';
import './css/ILETS-PTE/IPCuriousComponent.css';
import './css/ILETS-PTE/IPBenefits.css';
import './css/ILETS-PTE/IPPerfectChoiceComponent.css';
import './css/ILETS-PTE/IPTrainerComponent.css';
import './css/ILETS-PTE/IPFooterComponent.css';
import './css/ILETS-PTE/IPFaqComponent.css';
import './css/ILETS-PTE/IPContactFixButton.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SummerFiestaPage from './pages/SummerFiestaPage';
import ErrorPage from './pages/ErrorPage';
import ConfirmBookingPage from './pages/ConfirmBookingPage';
import IletsPtePage from './pages/IletsPtePage';
// import Other from "./components/Other"

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/summer-fiesta' element={<SummerFiestaPage />}></Route>

          <Route path='/ielts-coaching' element={<IletsPtePage />}></Route>

          
          <Route path='/booking-confirm' element={<ConfirmBookingPage />}></Route>
          
          <Route path='*' element={<ErrorPage />}></Route>
          {/* <Route path='/other' element={<Other />}></Route> */}

        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App