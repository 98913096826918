import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

const BookingForm = ({ setForm }) => {
  const [pickup, setPickup] = useState(false);

  // --------------------------------------------------------------------

  

  // const [checkboxes, setCheckboxes] = useState({
  //   "Rock Painting": false,
  //   "Art & Craft": false,
  //   "Puppet Show": false,
  //   "Yoga": false,
  //   "Coding": false,
  //   "Current Affairs": false,
  //   "Science Experiments": false,
  //   "French Classes": false,
  //   "Non-Fire Cooking": false,
  //   "Chess": false,
  // });
  
  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setCheckboxes({
  //     ...checkboxes,
  //     [name]: checked,
  //   });
  //   setUser({ activities: JSON.stringify(checkboxes) });
  // };

  
  

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    name: "",
    age: "",
    // activities: "",
    number: "",
    message: "",
    address: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch(
        "https://api.weexcellearningcenter.in/summer-fiest-booking",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Seat booked successfully");
        navigate("/booking-confirm");
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className="booking-form">
        <div>
          <div className="booking-form-close">
            <i
              onClick={(e) => {
                setForm(false);
              }}
              className="fa-solid fa-circle-xmark"
            ></i>
          </div>
          <div className="booking-form-content">
            {/* <div className="booking-form-content-heading-1">Welcome to We Excel Learning Center</div> */}
            {/* <div className="booking-form-content-heading-2">Hurry Up ! Only Limited Seats Available Book Your Children Seats </div> */}
            <div className="booking-form-content-heading-1">
              Book Your Child's Seat
            </div>
            <div className="booking-form-content-heading-2">
              Hurry Up ! Only Limited Seats Available{" "}
            </div>
            <div className="booking-form-content-form">
              <form onSubmit={handleSubmit}>
                <input
                  required
                  name="name"
                  type="text"
                  onChange={handleChange}
                  placeholder="Name of the child"
                />
                <input
                  required
                  name="age"
                  type="number"
                  onChange={handleChange}
                  placeholder="Age of the child"
                />

                {/* -------------------------- */}
                {/* <div>
                  <label className="booking-form-content-form-heading">
                    Select Interested Activities (Any 5) :
                  </label>

                  <div className="booking-form-content-form-checkbox">
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Rock Painting"
                      />{" "}
                      <label>Rock Painting</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Art & Craft"
                      />{" "}
                      <label>Art & Craft</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Puppet Show"
                      />{" "}
                      <label>Puppet Show</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Yoga"
                      />{" "}
                      <label>Yoga</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Coding"
                      />{" "}
                      <label>Coding</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Current Affairs"
                      />{" "}
                      <label>Current Affairs</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Science Experiments"
                      />{" "}
                      <label>Science Experiments</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="French Classes"
                      />{" "}
                      <label>French Classes</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Non-Fire Cooking"
                      />{" "}
                      <label>Non-Fire Cooking</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        name="Chess"
                      />{" "}
                      <label>Chess</label>
                    </div>
                  </div>

                  <label className="booking-form-content-form-heading-1">
                    *extra charges for add-on activities
                  </label>
                </div> */}
                {/* -------------------------- */}

                <input
                  required
                  name="number"
                  type="number"
                  placeholder="Phone Number"
                  onChange={handleChange}
                  />
                <textarea
                  name="message"
                  type="text"
                  placeholder="any special request"
                  onChange={handleChange}
                />

                <div className="booking-form-content-form-checkbox">
                  <label className="booking-form-content-form-heading">
                    Pick & Drop :
                  </label>
                  <div>
                    <span>
                      <input
                        required
                        onChange={handleChange}
                        onClick={(e) => setPickup(true)}
                        type="radio"
                        name="address"
                        
                      />
                      <label htmlFor="">yes</label>
                    </span>

                    <span>
                      <input
                        required
                        onChange={handleChange}
                        onClick={(e) => setPickup(false)}
                        type="radio"
                        name="address"
                        value="not interested in pickup"
                      />
                      <label htmlFor="">no</label>
                    </span>
                  </div>
                  <label className="booking-form-content-form-heading-1">
                    *free upto 6 Kms
                  </label>
                </div>
                {pickup ? (
                  <textarea
                    required
                    onChange={handleChange}
                    type="text"
                    name="address"
                    placeholder="Your address or area"
                  />
                ) : (
                  ""
                )}

                <button>Book Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
