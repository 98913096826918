import React from 'react'
import { Link } from 'react-router-dom'

const ConfirmBookingPage = () => {
  return (
    <div>
        <div className="confirm-booking">
        <div className="confirm-booking-heading-1">Thanks For Booking</div>
        <div className="confirm-booking-heading-2">Our team will contact you shortly</div>
        <div className="confirm-booking-heading-3">Contact : <Link target='_blank' to="tel:+91 89207 70571" >+91 89207 70571</Link></div>
        {/* <div className="confirm-booking-heading-4"><Link to="/">go to home</Link></div> */}

        </div>
    </div>
  )
}

export default ConfirmBookingPage