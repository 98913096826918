import React from 'react'
import Header from '../components/Header'
import ContactButton from '../components/ContactButton'
import WelcomeComponent from '../components/WelcomeComponent'
import TeacherComponent from '../components/TeacherComponent'
import Footer from '../components/Footer'
import WhyComponent from '../components/WhyComponent'

const SummerFiestaPage = () => {
  return (
    <div>
      
         <Header/>
        <WelcomeComponent/>
        <TeacherComponent/>
        <WhyComponent/>

        <Footer/>


        <ContactButton/>
    </div>
  )
}

export default SummerFiestaPage