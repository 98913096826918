import React, { useState } from "react";
// import img from "../media/images/maam-1.jpg"
import img from "../media/images/maam1.png"
import BookingForm from "./BookingForm";

const TeacherComponent = () => {
  
  const [form, setForm] = useState(false)
  return (
    <div>
      {
  form?
  <BookingForm setForm={setForm}/>
  :""
}
      <div className="teacher-component">
    <div>
        <div className="teacher-component-heading">Co-Founder at <span>We Excel Learning Center</span> </div>
        <div className="teacher-component-content">
        <div className="teacher-component-content-img"><img src={img} alt="" /></div>
        <div className="teacher-component-content-col">
            <div>

        <div className="teacher-component-content-heading-1">Deeksha K Khanna</div>
        <div className="teacher-component-content-heading-2">Co-Founder</div>
        <div className="teacher-component-content-text">
        Co-Founder at We Excel Learning Center | Freelance English and French Language Coach. My journey in education began in 2009 when I started as a teacher with GD Goenka School, Ryan International and Venkateshwar Global School.

Over the years, I have discovered my passion for guiding students achieve their dreams through mastering English language tests like the IELTS and PTE. 

Therefore, We Excel Learning Center is my one step forward to achieving that dream to guide learners through the process of learning with utmost joy and fun !
        </div>
        <div className="teacher-component-content-button"><button  onClick={e=>{setForm(true)}}>Book Seat Now</button></div>

        </div>

            </div>
        </div>

        </div>
    </div>
    </div>
  );
};

export default TeacherComponent;
