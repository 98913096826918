import React from "react";
import icon from "../../media/images/ilets-pte/Mask-group-5.png";
import correct from "../../media/images/ilets-pte/correct.png";

const IPPerfectChoiceComponent = () => {
  return (
    <div className="ip-perfect">
      <div>
        <div className="ip-perfect-heading">
          What Makes "We Excel Learning Center" The Perfect Choice For You?
        </div>

        
        
        <div className="ip-perfect-content">

            
          <div className="ip-perfect-content-col">
            <div className="ip-perfect-content-col-head"> <span>"We Excel Learning Center"</span></div>
            <div className="ip-perfect-content-col-content">

              <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Personalised Learning</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Customise your IELTS / PTE preparation to focus on what you need most</div>
                </div>
              </div>


              <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Personal Attention</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Batch size of 5 students with the facility of one on one coaching available for each and every student.</div>
                </div>
              </div>


              <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Band Score Predictor Test</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Know your weak areas and improve them with our expert trainers before appearing for the final IELTS / PTE exam.</div>
                </div>
              </div>


              <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Flexible Timings</div>
                  <div className="ip-perfect-content-col-content-point-text-text">We offer flexible timings for our classes and resources. It's like having a 24/7 study buddy.</div>
                </div>
              </div>


              <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Proven Results</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Our students consistently reach their target scores through our unique way of teaching.</div>
                </div>
              </div>

            </div>
          </div>

          <div className="ip-perfect-content-col-1"><img src={icon} alt="" /></div>

          
          <div className="ip-perfect-content-col">
            <div className="ip-perfect-content-col-head"><span>Others</span></div>

            <div className="ip-perfect-content-col-content">

            <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">No Flexible Schedule</div>
                  <div className="ip-perfect-content-col-content-point-text-text">No flexibility in class schedule</div>
                </div>
              </div>
                

            <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">No Night Time Classes</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Hard to manage work with studies</div>
                </div>
              </div>
                

            <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">No Personal Attention</div>
                  <div className="ip-perfect-content-col-content-point-text-text">Huge batch size of over 50 students</div>
                </div>
              </div>
                

            <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">No Mock Tests</div>
                  <div className="ip-perfect-content-col-content-point-text-text">No way of determining your current proficiency</div>
                </div>
              </div>
                

            <div className="ip-perfect-content-col-content-point">
                <div className="ip-perfect-content-col-content-point-icon"><img src={correct} alt="" /></div>
                <div className="ip-perfect-content-col-content-point-text">
                  <div className="ip-perfect-content-col-content-point-text-heading">Generic Feedback</div>
                  <div className="ip-perfect-content-col-content-point-text-text">No performance-based feedback</div>
                </div>
              </div>
                
                </div>
          </div>
          
          
          
        </div>
      </div>


      <div className="ip-register">
      <div className="ip-register-heading-1">Register Before The Offer Expires!!!</div>
      <div className="ip-register-heading-2">Enroll Now To Unlock The Bonus Worth <span> Rs. 12,796/-</span></div>

      <div className="ip-register-content">

      <div className="ip-register-content-col">
      <div className="ip-register-content-col-heading">Bonus 1</div>
      <div className="ip-register-content-col-text">List of most common used words in Listening.</div>
      <div className="ip-register-content-col-button">Worth Rs 3199/-</div>

      </div>

      <div className="ip-register-content-col">
      <div className="ip-register-content-col-heading">Bonus 2</div>
      <div className="ip-register-content-col-text">List of useful introductory phrases and topics related to Speaking.</div>
      <div className="ip-register-content-col-button">Worth Rs. 2199/-</div>

      </div>

      <div className="ip-register-content-col">
      <div className="ip-register-content-col-heading">Bonus 3</div>
      <div className="ip-register-content-col-text">Pdf of proper structure and thesis statement of writing task 2.</div>
      <div className="ip-register-content-col-button">Worth Rs 2199/-</div>

      </div>

      <div className="ip-register-content-col">
      <div className="ip-register-content-col-heading">Bonus 4</div>
      <div className="ip-register-content-col-text">Confidence Building Q&A Sessions</div>
      <div className="ip-register-content-col-button">Worth Rs 5199/-</div>

      </div>

      </div>


      
      </div>
      
      
    </div>
  );
};

export default IPPerfectChoiceComponent;
