import React from "react";
import icon from "../media/images/correct.png"

const WhyComponent = () => {
  return (
    <div id="why" className="why">
      <div>
        <div className="why-heading">
          Why <span>"We Excel Learning Center"?</span>
        </div>
        <div className="why-content">
          <div className="why-content-col-1"></div>
          <div className="why-content-col-2">
          <div className="why-content-col-text">
          The child after diving into various activities will yield a range of learning outcomes such as
          </div>
          <div className="why-content-col-points">
            <div><span><img src={icon} alt="" /></span>Gaining Physical Strength through Yoga and Dance</div>
            <div><span><img src={icon} alt="" /></span>Creativity through Art & Craft</div>
            <div><span><img src={icon} alt="" /></span>Scientific enquiry - Exploration-based activities such as science experiments</div>
            <div><span><img src={icon} alt="" /></span>Communication Skills through Story Telling and French</div>
            <div><span><img src={icon} alt="" /></span>Logical skills via using strategies while playing Chess</div>
            <div><span><img src={icon} alt="" /></span>Gross Motor Skills</div>
            <div><span><img src={icon} alt="" /></span>Fine Motor Skills</div>
            <div><span><img src={icon} alt="" /></span>Confidence</div>

          </div>
          </div>
          <div className="why-content-col-3"></div>
        </div>

        {/* Why us ?

The child after diving into various activities will yield a range of learning outcomes such as-
Gaining Physical Strength through Yoga and Dance, 
Creativity through Art & Craft
Scientific enquiry - Exploration-based activities such as science experiments
Communication Skills through Story Telling and French
Logical skills via using strategies while playing Chess
Confidence 
Gross Motor Skills
Fine Motor Skills */}
      </div>
    </div>
  );
};

export default WhyComponent;
